<template>
  <div class="row">
    <div class="col-12 " v-if="shopifySelectedEmbedd=='section'">
      <div class="row mb-5">
        <div class="col-12">
          <p class="mb-0 sidebarElementTitle">Embed as a section</p>
          <div class="">
            <a
              :href="embeddFAQLink"
              target="_blank"
              class="helpLinks sidebarElementSubTitle"
              >How to embed quiz to your platform?</a
            >
          </div>
        </div>

        <div class="col-12 mt-3 mb-5">
          <div class="d-flex">
            <p class="m-0 hashText">#</p>
            <div class="d-flex align-items-center justify-content-center ml-5">
              <div class="PreviewLinkInput d-flex align-items-center pr-3 py-2">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  disabled
                  v-model="quizzID"
                />
                <div style="cursor: pointer" @click="CopyToClipBoard(quizzID)">
                  <span class="mr-2 sidebarElementSubTitle">Copy Quiz ID</span>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_342_3141)">
                    <path
                      d="M4.16675 12.5H3.33341C2.89139 12.5 2.46746 12.3244 2.1549 12.0119C1.84234 11.6993 1.66675 11.2754 1.66675 10.8334V3.33335C1.66675 2.89133 1.84234 2.4674 2.1549 2.15484C2.46746 1.84228 2.89139 1.66669 3.33341 1.66669H10.8334C11.2754 1.66669 11.6994 1.84228 12.0119 2.15484C12.3245 2.4674 12.5001 2.89133 12.5001 3.33335V4.16669M9.16675 7.50002H16.6667C17.5872 7.50002 18.3334 8.24621 18.3334 9.16669V16.6667C18.3334 17.5872 17.5872 18.3334 16.6667 18.3334H9.16675C8.24627 18.3334 7.50008 17.5872 7.50008 16.6667V9.16669C7.50008 8.24621 8.24627 7.50002 9.16675 7.50002Z"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_342_3141">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 ">
          <div class="row">
            <div class="col-12">
              <p
                class="StepTitle pl-1"
                v-for="(stepTitle, index) in Step1Titles"
                :key="index"
              >
                {{ index + 1 }}. {{ stepTitle.title }}
              </p>
            </div>
            <div
              class="col-12 mediaDiv"
              v-for="embedStep in sectionEmbedSteps"
              :key="embedStep.id"
            >
              <div class="embedMedia">
                <video
                  ref="videoRef"
                  :src="`${embedStep.media}`"
                  alt=""
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 " v-if="shopifySelectedEmbedd=='popup'">
      <div class="row mb-5">
        <div class="col-12">
          <p class="mb-0 sidebarElementTitle">Embed as a Pop-up</p>
          <div class="">
            <a
              :href="embeddFAQLink"
              target="_blank"
              class="helpLinks sidebarElementSubTitle"
              >How to embed quiz to your platform?</a
            >
          </div>
        </div>

        <div class="col-12 mt-3 mb-5">
          <div class="d-flex align-items-center">
            <p class="m-0 hashText">#</p>
            <div class="d-flex align-items-center justify-content-center ml-3">
              <div class="PreviewLinkInput d-flex align-items-center pr-3 py-2">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  disabled
                  v-model="quizzID"
                />
                <div style="cursor: pointer" @click="CopyToClipBoard(quizzID)">
                  <span class="mr-2 sidebarElementSubTitle">Copy Quiz ID</span>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_342_3141)">
                    <path
                      d="M4.16675 12.5H3.33341C2.89139 12.5 2.46746 12.3244 2.1549 12.0119C1.84234 11.6993 1.66675 11.2754 1.66675 10.8334V3.33335C1.66675 2.89133 1.84234 2.4674 2.1549 2.15484C2.46746 1.84228 2.89139 1.66669 3.33341 1.66669H10.8334C11.2754 1.66669 11.6994 1.84228 12.0119 2.15484C12.3245 2.4674 12.5001 2.89133 12.5001 3.33335V4.16669M9.16675 7.50002H16.6667C17.5872 7.50002 18.3334 8.24621 18.3334 9.16669V16.6667C18.3334 17.5872 17.5872 18.3334 16.6667 18.3334H9.16675C8.24627 18.3334 7.50008 17.5872 7.50008 16.6667V9.16669C7.50008 8.24621 8.24627 7.50002 9.16675 7.50002Z"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_342_3141">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <p class="m-0 hashText">#</p>
            <div class="d-flex align-items-center justify-content-center ml-3">
              <div class="PreviewLinkInput d-flex align-items-center pr-3 py-2">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  disabled
                  v-model="tagId"
                />
                <div style="cursor: pointer" @click="CopyToClipBoard(tagId)">
                  <span class="mr-2 sidebarElementSubTitle">Copy trigger</span>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_342_3141)">
                    <path
                      d="M4.16675 12.5H3.33341C2.89139 12.5 2.46746 12.3244 2.1549 12.0119C1.84234 11.6993 1.66675 11.2754 1.66675 10.8334V3.33335C1.66675 2.89133 1.84234 2.4674 2.1549 2.15484C2.46746 1.84228 2.89139 1.66669 3.33341 1.66669H10.8334C11.2754 1.66669 11.6994 1.84228 12.0119 2.15484C12.3245 2.4674 12.5001 2.89133 12.5001 3.33335V4.16669M9.16675 7.50002H16.6667C17.5872 7.50002 18.3334 8.24621 18.3334 9.16669V16.6667C18.3334 17.5872 17.5872 18.3334 16.6667 18.3334H9.16675C8.24627 18.3334 7.50008 17.5872 7.50008 16.6667V9.16669C7.50008 8.24621 8.24627 7.50002 9.16675 7.50002Z"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_342_3141">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <p
                class="StepTitle pl-1"
                v-for="(stepTitle, index) in Step2Titles"
                :key="index"
              >
                {{ index + 1 }}. {{ stepTitle.title }}
              </p>
            </div>
            <div
              class="col-12"
              v-for="tagStep in tagEmbedSteps"
              :key="tagStep.id"
            >
              <div class="embedMedia mt-4">
                <video
                  ref="videoRef"
                  :src="`${tagStep.media}`"
                  alt=""
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["QuizKey","shopifySelectedEmbedd"],
  data() {
    return {
      Step1Titles: [
        {
          title: "Add Quizell App Section",
        },
        {
          title: "Configure Quiz Settings",
        },
        {
          title: "Save and Test",
        },
      ],
      Step2Titles: [
        {
          title: "Add Quizell App Section",
        },
        {
          title: "Configure Popup Settings",
        },
        {
          title: "Add Popup Trigger",
        },
      ],
      sectionEmbedSteps: [
        {
          id: 1,
          title: "Add Quizell App Section",
          media:
            "https://cdn.shopify.com/videos/c/o/v/9f1d55bc330c4fef8bd9a1adcd71d50a.mp4",
        },
      ],
      tagEmbedSteps: [
        {
          id: 1,
          title: "Add Quizell App Section",
          //  media:'https://cdn.shopify.com/videos/c/o/v/edbaa04de740468aa4ccdabbaae0bad7.mp4'
          media:
            "https://cdn.shopify.com/videos/c/o/v/20a8aced43714c119e5a5410a71470d1.mp4",
        },
        // {
        //     id:2,
        //     title:'Configure Popup Settings',
        //      media:'https://images.quizell.com/website%2FKS7a1lqy.mp4'
        // },
        // {
        //     id:3,
        //     title:'Add Popup Trigger',
        //      media:'https://images.quizell.com/website%2F_WNyuCdx.mp4'
        // },
      ],
      quizzID: this.QuizKey,
      tagId: `#quizell-${this.QuizKey}`,
    };
  },
  methods: {
    async CopyToClipBoard(copyText) {
      const vm = this;
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then(function (result) {
          if (result.state == "granted") {
            navigator.clipboard.writeText(copyText).then(
              () => {
                vm.$toasted.show("Link Copied!", {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 2000,
                });
              },
              () => {
                vm.$toasted.show(
                  "Unable to Copy! Please manually select the text!",
                  {
                    theme: "toasted-primary",
                    position: "bottom-center",
                    duration: 2000,
                  }
                );
              }
            );
          } else {
            document.execCommand("copy");
          }
        });
      //     navigator.clipboard.writeText(copyText).then(
      //   () => {
      //      this.$toasted.show("Link Copied!", {
      //       theme: "toasted-primary",
      //       position: "bottom-center",
      //       duration: 2000,
      //     });
      //   },
      //   () => {
      //      this.$toasted.show("Unable to Copy! Please manually select the text!", {
      //       theme: "toasted-primary",
      //       position: "bottom-center",
      //       duration: 2000,
      //     });
      //   },
      // );
    },
  },
  computed: {
    embeddFAQLink() {
      if (this.getUserType == "shopify")
        return `https://support.quizell.com/blogs/embed/how-to-embed-quizell-with-your-website-shopify-theme-2-0`;
      if (this.getUserType == "wix")
        return `https://support.quizell.com/blogs/embed/how-to-add-your-wix-quiz-to-your-website`;
      if (this.getUserType == "bc")
        return `https://support.quizell.com/blogs/embed/embed-your-quiz-on-bigcommerce`;
      return `https://support.quizell.com/blogs/embed/how-to-embed-the-quiz-on-any-html-section`;
    },
  },
};
</script>

<style scoped>
.hashText {
  color: #b3afb6;
  font-size: 20px;
  font-weight: 700;
}

/* .SearchContainer {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
  border-radius: 12px;
} */

.PreviewLinkInput {
  border-radius: 8px;
  border: 1px solid #ededed;
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.PreviewLinkInput input {
  background: transparent;
  border: none;
  outline: none;
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.PreviewLinkInput input:focus {
  border: none;
  outline: none;
}
.StepTitle {
  color: #667085;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.embedMedia {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.embedMedia video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.helpLinks:hover {
  color: #4d1b7e;
  text-decoration: underline;
}

.mediaDiv{
  margin-top:25px
}

@media (min-width:1650px){
  .mediaDiv{
  margin-top:10px;
}
}
</style>
